<template>
  <v-layout max-width class="flex-column white" style="min-height:100%;">
    <div class="flex-y-center flex-justify-between px-2" style="border-bottom:1px solid #e8e8e8">
      <v-tabs v-model="tab" @change="tabChange">
        <v-tab :href="`#0`">前台</v-tab>
        <v-tab :href="`#1`">后台</v-tab>
      </v-tabs>
      <!-- <v-btn dark @click="drawer.data={},drawer.parentId=[],drawer.show=true" small color="#00bea9" depressed min-width='32' class="mr-2" style="height:34px; padding:0;">
                <v-icon size="20">mdi-plus</v-icon>
            </v-btn> -->
    </div>
    <div class=" flex-1" style="">
      <div class=" mx-4 my-2 flex-justify-between">
        <v-btn dark small color="#00bea9" depressed min-width="32" class="mr-2 flex-0 flex-shrink-0" height="30" style=" padding:0 8px;" @click="drawer.data={},drawer.parentId=[],drawer.show=true">
          <v-icon size="18">mdi-plus</v-icon>新增{{ tab=='0'?'前台':'后台' }}菜单
        </v-btn>
        <div class=" flex-y-center">
          <div class="flex-y-center mr-1">
            <span class=" flex-0 flex-shrink-0">菜单权限类型：</span>
            <form-item
              v-model="search.data.permsRole"
              class=" flex-0 flex-shrink-0"
              :options="[{text:'组织菜单权限',value:'0'},{text:'项目菜单权限',value:'1'}]"
              placeholder="菜单权限类型"
              style="width: 150px"
              dense
              type="select"
              @input="getList()"
            />
          </div>
        </div>
      </div>
      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="99"
        class="pb-5 max-width px-4 header-grey"
        hide-default-footer
        :loading="listLoading"
        item-key="id"
        no-data-text="暂无查询数据"
        disable-sort
      >
        <template v-slot:header.name>
          <span class="ml-8">名称</span>
        </template>
        <template v-slot:item="{item,index}">

          <!-- <tree :item="item"></tree> -->
          <tr>
            <!-- <template v-if="item.children&&item.children.length"> -->
            <!-- <td @click="expand(item,index)">

                            <template v-if="!item.isLeaf">
                                <v-icon v-if="item.show">mdi-chevron-down</v-icon>
                                <v-icon v-else>mdi-chevron-up</v-icon>
                            </template>
                        </td> -->
            <td class=" flex-y-center">
              <div style="width:24px">

                <span v-if="!item.isLeaf" @click="expand(item,index)">
                  <template>
                    <v-icon v-if="item.show">mdi-chevron-down</v-icon>
                    <v-icon v-else>mdi-chevron-up</v-icon>
                  </template>
                </span>
              </div>
              {{ item.name }}
            </td>
            <td>{{ item.url }}</td>
            <td>{{ item.perms }}</td>

            <td>{{ item.sortNo }}</td>
            <!-- <td>{{ item.menuType==1?'子菜单':(item.menuType==2?'权限按钮':'一级菜单') }}</td> -->
            <!-- <td>{{ parseInt(item.menuType)>4?'权限按钮':(parseInt(item.menuType+1)+'级菜单') }}</td> -->
            <td>{{ menuType[item.menuType].name }}</td>
            <td>
              <div class=" font-overflow" style="max-width:200px;" :title="item.description">{{ item.description }}</div>
            </td>
            <td>
              <div class=" flex-y-center">
                <v-icon color="primary" size="18" title="编辑" class=" mr-1" @click="showEdit(item)">mdi-square-edit-outline</v-icon>
                <v-icon color="error" size="20" title="删除" class=" mr-1" @click="showDel(item.id)">mdi-delete-outline</v-icon>
              </div>
            </td>
          </tr>
          <template v-if="item.children&&item.children&&item.show">
            <template v-for="(_item,_index) in item.children">
              <tr :key="item.id+'-'+_index">
                <!-- <td @click="expand(_item,index)">
                                    <template v-if="!_item.isLeaf">
                                        <v-icon v-if="_item.show">mdi-chevron-down</v-icon>
                                        <v-icon v-else>mdi-chevron-up</v-icon>
                                    </template>
                                </td> -->
                <td>
                  <div class="pl-7 flex-y-center ">
                    <div style="width:24px;height:24px;" class=" tableTree-line">
                      <span v-if="!_item.isLeaf" @click="expand(_item,index)">
                        <template>
                          <v-icon v-if="_item.show">mdi-chevron-down</v-icon>
                          <v-icon v-else>mdi-chevron-up</v-icon>
                        </template>
                      </span>
                    </div>
                    <span>{{ _item.name }}</span>
                  </div>
                </td>
                <td>{{ _item.url }}</td>
                <td>{{ _item.perms }}</td>
                <td>{{ _item.sortNo }}</td>
                <td>{{ menuType[_item.menuType].name }}</td>
                <td>
                  <div class=" font-overflow" style="max-width:200px;" :title="_item.description">{{ _item.description }}</div>
                </td>
                <td>
                  <v-icon color="primary" size="18" title="编辑" class=" mr-1" @click="showEdit(_item)">mdi-square-edit-outline</v-icon>
                  <v-icon color="error" size="20" title="删除" class=" mr-1" @click="showDel(_item.id)">mdi-delete-outline</v-icon>
                  <!-- <a class=" mr-2" @click="showEdit(_item)">编辑</a>
                                    <a class=" mr-2" @click="showDel(_item.id)">删除</a> -->
                </td>
              </tr>
              <template v-if="_item&&_item.children&&_item.children.length&&_item.show">
                <template v-for="(_t,_i) in _item.children">
                  <tr :key="_t.id+'-'+_i">
                    <td>
                      <div class=" flex-y-center" style="padding-left:56px;">
                        <div style="width:24px;height:24px;" class=" tableTree-line">
                          <span v-if="!_t.isLeaf" @click="expand(_t,_i)">
                            <template>
                              <v-icon v-if="_t.show">mdi-chevron-down</v-icon>
                              <v-icon v-else>mdi-chevron-up</v-icon>
                            </template>
                          </span>
                        </div>
                        <span>{{ _t.name }}</span>
                      </div>
                    </td>
                    <td>{{ _t.url }}</td>
                    <td>{{ _t.perms }}</td>
                    <td>{{ _t.sortNo }}</td>
                    <td>{{ menuType[_t.menuType].name }}</td>
                    <td>
                      <div class=" font-overflow" style="max-width:200px;" :title="_t.description">{{ _t.description }}</div>
                    </td>
                    <td>
                      <v-icon color="primary" size="18" title="编辑" class=" mr-1" @click="showEdit(_t)">mdi-square-edit-outline</v-icon>
                      <v-icon color="error" size="20" title="删除" class=" mr-1" @click="showDel(_t.id)">mdi-delete-outline</v-icon>
                      <!-- <a class=" mr-2" @click="showEdit(_t)">编辑</a>
                                            <a class=" mr-2" @click="showDel(_t.id)">删除</a> -->
                    </td>
                  </tr>

                  <template v-if="_t&&_t.children&&_t.children.length&&_t.show">
                    <template v-for="(_t4,_i4) in _t.children">
                      <tr :key="_t4.id+'-'+_i4">
                        <td>
                          <div class=" flex-y-center" style="padding-left:84px;">
                            <div style="width:24px;height:24px;" class=" tableTree-line">
                              <span v-if="!_t4.isLeaf" @click="expand(_t4,_i)">
                                <template>
                                  <v-icon v-if="_t4.show">mdi-chevron-down</v-icon>
                                  <v-icon v-else>mdi-chevron-up</v-icon>
                                </template>
                              </span>
                            </div>
                            <span>{{ _t4.name }}</span>
                          </div>
                        </td>
                        <td>{{ _t4.url }}</td>
                        <td>{{ _t4.perms }}</td>
                        <td>{{ _t4.sortNo }}</td>
                        <td>{{ menuType[_t4.menuType].name }}</td>
                        <td>
                          <div class=" font-overflow" style="max-width:200px;" :title="_t4.description">{{ _t4.description }}</div>
                        </td>
                        <td>
                          <v-icon color="primary" size="18" title="编辑" class=" mr-1" @click="showEdit(_t4)">mdi-square-edit-outline</v-icon>
                          <v-icon color="error" size="20" title="删除" class=" mr-1" @click="showDel(_t4.id)">mdi-delete-outline</v-icon>
                          <!-- <a class=" mr-2" @click="showEdit(_t4)">编辑</a>
                                                    <a class=" mr-2" @click="showDel(_t4.id)">删除</a> -->
                        </td>
                      </tr>
                      <template v-if="_t4&&_t4.children&&_t4.children.length&&_t4.show">
                        <template v-for="(_t5,_i5) in _t4.children">
                          <tr :key="_t5.id+'-'+_i5">
                            <td>
                              <div class=" flex-y-center" style="padding-left:84px;">
                                <div style="width:24px;height:24px;" class=" tableTree-line">
                                  <span v-if="!_t5.isLeaf" @click="expand(_t5,_i)">
                                    <template>
                                      <v-icon v-if="_t5.show">mdi-chevron-down</v-icon>
                                      <v-icon v-else>mdi-chevron-up</v-icon>
                                    </template>
                                  </span>
                                </div>
                                <span>{{ _t5.name }}</span>
                              </div>
                            </td>
                            <td>{{ _t5.url }}</td>
                            <td>{{ _t5.perms }}</td>
                            <td>{{ _t5.sortNo }}</td>
                            <td>{{ menuType[_t5.menuType].name }}</td>
                            <td>
                              <div class=" font-overflow" style="max-width:200px;" :title="_t5.description">{{ _t5.description }}</div>
                            </td>
                            <td>
                              <v-icon color="primary" size="18" title="编辑" class=" mr-1" @click="showEdit(_t5)">mdi-square-edit-outline</v-icon>
                              <v-icon color="error" size="20" title="删除" class=" mr-1" @click="showDel(_t5.id)">mdi-delete-outline</v-icon>
                              <!-- <a class=" mr-2" @click="showEdit(_t5)">编辑</a>
                                                            <a class=" mr-2" @click="showDel(_t5.id)">删除</a> -->
                            </td>
                          </tr>
                        </template>
                      </template>
                    </template>
                  </template>
                </template>
              </template>
            </template>
          </template>

        </template>
      </v-data-table>
    </div>

    <!-- 编辑 新增 路由 start -->
    <v-navigation-drawer
      v-model="drawer.show"
      temporary
      app
      right
      width="700"
    >
      <form v-if="drawer.show" class="max-height flex-column overflow-hidden" @submit.prevent="submit()">
        <div class=" flex-0 flex-justify-between flex-y-center py-3 px-5" style="border-bottom:1px solid #e1e1e1">
          <span class="text-h6">{{ drawer.data.id?'编辑':'新增' }}</span>
          <v-icon @click="drawer.show=false,drawer.data={},drawer.parentId=[]">mdi-close</v-icon>
        </div>
        <div class="flex-1 flex-column overflow-auto mt-7 mx-12 px-12">
          <div class="mb-6 flex-align-start">
            <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>名称：</div>
            <input
              v-model="drawer.data.name"
              class="flex-1 default-input py-1 subtitle-1"
              required
              placeholder="名称"
              type="text"
            >
          </div>
          <div class="mb-6 flex-align-start">
            <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">父级：</div>
            <v-menu
              bottom
              max-height="300"
              offset-overflow
              transition="slide-y-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <div class="default-input py-1" color="primary" v-on="on">
                  <input
                    placeholder="上级部门"
                    required
                    readonly
                    class="max-width"
                    type="text"
                    :value="(drawer.parentId.length&&drawer.parentId[0].name)||''"
                  >
                </div>
              </template>
              <v-card class="elevation-0">
                <!-- list -->
                <v-treeview
                  activatable
                  :items="menuTree"
                  item-text="name"
                  :active.sync="drawer.parentId"
                  return-object
                />
              </v-card>
            </v-menu>
            <!-- <input class="flex-1 default-input py-1 subtitle-1" placeholder="父级" required type="text"
                                v-model="drawer.data.password"/> -->
          </div>
          <div class="mb-6 flex-align-start">
            <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>路径：</div>
            <input
              v-model="drawer.data.url"
              class="flex-1 default-input py-1 subtitle-1"
              placeholder="路径"
              required
              type="text"
            >
          </div>
          <!-- <div class="mb-6 flex-align-start">
            <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>组件：</div>
            <input
              v-model="drawer.data.component"
              class="flex-1 default-input py-1 subtitle-1"
              placeholder="组件"
              required
              type="text"
            >
          </div> -->
          <div class="mb-6 flex-align-start">
            <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">图标：</div>
            <input
              v-model="drawer.data.icon"
              class="flex-1 default-input py-1 subtitle-1"
              placeholder="图标"
              type="text"
            >
          </div>
          <div class="mb-6 flex-align-start">
            <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">排序：</div>
            <input
              v-model="drawer.data.sortNo"
              class="flex-1 default-input py-1 subtitle-1"
              placeholder="排序"
              type="text"
            >
          </div>
          <div class="mb-6 flex-align-start">
            <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>路由类型：</div>

            <!-- :options="[{text:'一级菜单',value:0},{text:'子菜单',value:1},{text:'权限按钮',value:2},]" -->
            <form-item
              v-model="drawer.data.menuType"
              class=" flex-1 flex-shrink-0"
              :options="[{text:'一级菜单',value:0},
                         {text:'二级菜单',value:1},{text:'三级菜单',value:2},{text:'四级菜单',value:3},{text:'五级菜单',value:4},{text:'权限按钮',value:5}]"
              placeholder="路由类型"
              required
              dense
              type="select"
            />
          </div>
          <div class="mb-6 flex-align-start">
            <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">权限策略：</div>
            <form-item
              v-model="drawer.data.permsType"
              class=" flex-1 flex-shrink-0"
              :options="[{text:'显示',value:'1'},{text:'禁用',value:'2'}]"
              placeholder="权限策略"
              dense
              type="select"
            />
          </div>
          <div class="mb-6 flex-align-start">
            <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">授权标识：</div>
            <input
              v-model="drawer.data.perms"
              class="flex-1 default-input py-1 subtitle-1"
              placeholder="授权标识"
              type="text"
            >
          </div>

        </div>
        <div class=" flex-0 flex-shrink-0 py-3 px-5 flex-justify-end" style="border-top:1px solid #e1e1e1;">
          <v-btn width="100" height="30" class="mr-2" depressed @click="drawer.show = false,drawer.data={},drawer.parentId=[]">取消</v-btn>
          <v-btn type="submit" height="30" :loading="btnLoading" color="primary" width="100" depressed>提交</v-btn>
        </div>
      </form>

    </v-navigation-drawer>
    <!-- 编辑 新增 路由 end -->

  </v-layout>
</template>

<script>
export default {

  data() {
    return {
      tab: '0',
      search: {
        data: {
          permsRole: '0'
        }
      },
      listLoading: false,
      btnLoading: false,
      headers: [
        // { text: "", value: "",width:'40' },
        { text: '名称', value: 'name', width: '200', sortable: false },
        { text: '路由', value: 'url' },
        { text: '授权标识', value: 'perms' },
        { text: '排序', value: 'sortNo', width: '80' },
        { text: '类型', value: 'menuType', width: '80' },
        { text: '权限描述', value: 'description', width: '180' },
        { text: '操作', value: 'active', width: '80', sortable: false }
      ],
      list: [],
      menuTree: [],
      data: {},
      drawer: {
        show: false,
        data: {},
        parentId: []
      },
      menuType: {
        0: { name: '一级菜单', id: 0 },
        1: { name: '二级菜单', id: 1 },
        2: { name: '三级菜单', id: 2 },
        3: { name: '四级菜单', id: 3 },
        4: { name: '五级菜单', id: 4 },
        5: { name: '权限按钮', id: 5 }
      }
    }
  },
  mounted() {
    this.getList()
    this.getMenuTree()
    // let param = new URLSearchParams()
    // param.append('permsCategory', '1')
  },
  methods: {
    // 获取路由列表
    getList() {
      // getUserPermissionByToken
      this.listLoading = true
      this.$http.get(this.$api.getSystemMenuList, { data: { permsCategory: this.tab, permsRole: this.search.data.permsRole }}).then(res => {
        this.listLoading = false
        this.list = res.result || []
      }).catch(() => {
        this.listLoading = false
      })
    },
    // 点击展开按钮 查询子菜单
    getSystemSubmenu(id) {
      const _this = this
      this.listLoading = true
      this.$http.get(this.$api.getSystemSubmenu, { data: { parentId: id }}).then(res => {
        this.listLoading = false
        let _f = false
        const _h = (arr) => {
          arr.forEach(item => {
            if (!_f) {
              if (item.id === id) {
                _f = true
                _this.$set(item, 'show', true)
                item.children = res.result
              } else {
                if (item.children && item.children.length) _h(item.children)
              }
            }
          })
        }
        if (res.result && res.result.length) _h(this.list)
      })
    },
    // 获取路由树
    getMenuTree() {
      const param = new URLSearchParams()
      param.append('permsCategory', this.tab)
      this.menuTree = []
      this.$http.get(this.$api.getUserPermissionByToken, { data: param }).then(res => {
        this.menuTree = res.result
      })
    },
    // 切换前后台tab
    tabChange() {
      this.getList()
      this.getMenuTree()
    },
    // 路由 展开
    expand(item, index) {
      this.$set(item, 'show', !item.show)
      this.$forceUpdate()
      if (item.show) this.getSystemSubmenu(item.id, index)
    },
    // 删除路由
    showDel(id) {
      // if(confirm('确定要删除吗？')){
      //     this.$http.delete(this.$api.permissionDelete,{data:{id:id}}).then(res => {
      //         this.$message.success(res.message)
      //         this.getList()
      //     });
      // }
      this.$confirm({ text: '是否确认删除？' }).then((vm) => {
        vm.loading = true
        this.$http.delete(this.$api.permissionDelete, { data: { id: id }}).then(res => {
          vm.show = false
          vm.loading = false
          this.$message.success(res.message)
          this.getList()
        }).catch(() => {
          vm.show = false
          vm.loading = false
        })
      }).catch(() => {})
    },
    // 编辑路由弹窗
    showEdit(item) {
      this.drawer.data = { ...item }
      // this.drawer.data.icon = 'mdi-shield-check-outline'
      this.drawer.show = true
      // drawer.parentId
      this.drawer.parentId = []
      let _f = false
      let _data = {}
      const _h = arr => {
        arr.forEach(_item => {
          if (!_f) {
            if (_item.id === item.parentId) {
              _data = { ..._item }
              _f = true
            } else {
              if (_item.children && _item.children.length) _h(_item.children)
            }
          }
        })
      }
      if (item.parentId) {
        _h(this.list)
        this.drawer.parentId.push(_data)
      }
    },
    // 路由添加 编辑 确认
    submit() {
      let url = this.$api.permissionAdd
      let method = 'post'
      if (this.drawer.data.id) {
        url = this.$api.permissionEdit
        method = 'put'
      }
      const _data = { ...this.drawer.data }
      _data.parentId = this.drawer.parentId.length ? this.drawer.parentId[0].id : ''
      _data.permsRole = this.search.data.permsRole
      _data.permsCategory = this.tab
      this.btnLoading = true
      this.$http[method](url, { data: { ..._data }}).then(res => {
        this.$message.success(res.message)
        this.getList()
        this.drawer.show = false
        this.btnLoading = false
      }).catch(() => {
        this.btnLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-title{
    width:130px;
    color: #777;
}
// .tableTree-line{
//     position: relative;
//     &:before {
//         content: '';
//         position: absolute;
//         bottom: 10px;
//         left: -18px;
//         width: 1px;
//         height: 22px;
//         background: #DFDFDF;
//     }
//     &:after {
//         content: '';
//         position: absolute;
//         bottom: 10px;
//         left: -18px;
//         width: 18px;
//         height: 1px;
//         background: #DFDFDF;
//     }
// }
</style>
